import type { Components, Theme } from "@mui/material";

export const MuiOutlinedInput: Components<Theme>["MuiOutlinedInput"] = {
  styleOverrides: {
    input: ({ theme }) =>
      theme.unstable_sx({
        px: 1.75,
        py: 1.25,
        fontWeight: 400,
        fontSize: 16,
        color: theme.palette.primary.main
      }),
    root: ({ theme }) =>
      theme.unstable_sx({
        "& textarea": {
          p: 0
        },
        "& input:focus + fieldset": {
          boxShadow:
            "0px 0px 0px 4px rgba(214, 100, 20, 0.13), 0px 1px 2px rgba(217, 121, 55, 0.39) !important"
        },
        "& input:focus + .MuiInputAdornment-root + fieldset": {
          //TODO check why don't apply to PasswordField component
          boxShadow:
            "0px 0px 0px 4px rgba(214, 100, 20, 0.13), 0px 1px 2px rgba(217, 121, 55, 0.39) !important"
        },
        "&:hover fieldset": {
          boxShadow:
            "0px 0px 0px 4px rgba(214, 100, 20, 0.13), 0px 1px 2px rgba(217, 121, 55, 0.39)"
        },
        "& fieldset": {
          boxShadow: "0px 2px 6px rgba(167, 167, 167, 0.25)",
          borderColor: "#E0E0E0",
          borderWidth: 1
        },
        borderRadius: 2.5
      })
  }
};
