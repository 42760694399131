import React from "react";

import { getLogger } from "loglevel";
import { withRouter } from "next/router";

import { Box, CircularProgress } from "@mui/material";

const log = getLogger("error-boundary");

class Error extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    log.error({ error });
    return {
      error: error
    };
  }

  componentDidCatch(error, errorInfo) {
    log.error({ error, errorInfo });
    this.setState(
      {
        error: error,
        errorInfo: errorInfo
      },
      () => {
        if (process?.env?.NODE_ENV !== "development") {
          this.props.router.push({
            pathname: "/500",
            query: {
              error: JSON.stringify(error),
              errorInfo: JSON.stringify(errorInfo)
            }
          });
        }
      }
    );
  }

  render() {
    if (this.state.error) {
      return (
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <CircularProgress />
        </Box>
      );
    }

    return this.props.children;
  }
}

const ErrorBoundary = withRouter(Error);

export default ErrorBoundary;
