import { ThemeOptions } from "@mui/material";

const SIZES = {
  header: { height: 44 },
  nav: { height: 48 }
};

interface CustomProperties {
  header: ThemeOptions["header"];
  nav: ThemeOptions["nav"];
  variables: ThemeOptions["variables"];
}

export const customProperties: CustomProperties = {
  header: {
    height: SIZES.header.height
  },
  nav: {
    height: SIZES.nav.height
  },
  variables: {
    headerHeight: `${SIZES.header.height}px`,
    navBarHeight: `${SIZES.nav.height}px`,
    vhWithMargins: `calc(100vh - ${SIZES.header.height}px - ${SIZES.nav.height}px)`,
    vhWithTopMargin: `calc(100vh - ${SIZES.header.height}px)`,
    vhWithBottomMargin: `calc(100vh - ${SIZES.nav.height}px)`
  }
};
