import type { Components, Theme } from "@mui/material";

export const MuiTypography: Components<Theme>["MuiTypography"] = {
  defaultProps: {
    color: "primary",
    fontWeight: 300,
    fontSize: 14,
    lineHeight: 1.5
  }
};
