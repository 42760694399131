import type { Components, Theme } from "@mui/material";

export const MuiRating: Components<Theme>["MuiRating"] = {
  styleOverrides: {
    root: ({ theme }) =>
      theme.unstable_sx({
        "& .MuiRating-icon path": {
          fill: theme.palette.orange.main
        }
      })
  }
};
