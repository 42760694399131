import type { Components, Theme } from "@mui/material";
import { alpha } from "@mui/material/styles";

export const MuiButton: Components<Theme>["MuiButton"] = {
  defaultProps: {
    variant: "contained"
  },
  styleOverrides: {
    root: ({ theme }) =>
      theme.unstable_sx({
        fontWeight: 500,
        fontSize: 14,
        lineHeight: 1.5
      }),
    contained: ({ theme, ownerState }) =>
      theme.unstable_sx({
        px: 2,
        py: 1.25,
        borderRadius: 20,
        height: 41,
        color:
          ownerState.color !== "primary"
            ? theme.palette.primary.main
            : theme.palette.white.main,
        ":disabled": {
          // @ts-ignore
          bgcolor: alpha(theme.palette[ownerState.color].main, 0.38)
        }
      }),
    outlined: ({ theme }) =>
      theme.unstable_sx({
        px: 2,
        py: 1.25,
        borderRadius: 20,
        textTransform: "none"
      }),
    text: ({ theme }) => theme.unstable_sx({ p: 0 })
  }
};
