import { MuiButton } from "~styles/theme/main/themeOptions/components/MuiButton";
import { MuiCheckbox } from "~styles/theme/main/themeOptions/components/MuiCheckbox";
import { MuiCircularProgress } from "~styles/theme/main/themeOptions/components/MuiCircularProgress";
import { MuiInputLabel } from "~styles/theme/main/themeOptions/components/MuiInputLabel";
import { MuiOutlinedInput } from "~styles/theme/main/themeOptions/components/MuiOutlinedInput";
import { MuiRating } from "~styles/theme/main/themeOptions/components/MuiRating";
import { MuiTextField } from "~styles/theme/main/themeOptions/components/MuiTextField";
import { MuiTypography } from "~styles/theme/main/themeOptions/components/MuiTypography";

import type { ThemeOptions } from "@mui/material";

export const themeComponents: ThemeOptions["components"] = {
  MuiButton,
  MuiTypography,
  MuiTextField,
  MuiInputLabel,
  MuiOutlinedInput,
  MuiCheckbox,
  MuiCircularProgress,
  MuiRating
};
