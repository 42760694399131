import { ThemeOptions, createTheme } from "@mui/material";

const { palette } = createTheme();
export const themePalette: ThemeOptions["palette"] = {
  primary: {
    main: "#42210A", // brown
    light: "#684D3B",
    contrastText: "#fff"
  },
  secondary: {
    main: "#1B1B1B",
    contrastText: "#fff"
  },
  white: palette.augmentColor({
    color: {
      main: "#FFFFFF"
    }
  }),
  gray: palette.augmentColor({
    color: {
      main: "#E0E0E0"
    }
  }),
  green: palette.augmentColor({
    color: {
      main: "#5CE0B0"
    }
  }),
  pink: palette.augmentColor({
    color: {
      main: "#FD7E60"
    }
  }),
  orange: palette.augmentColor({
    color: {
      main: "#EE9000"
    }
  }),
  yellow: palette.augmentColor({
    color: {
      main: "#FFC43A"
    }
  }),
  info: {
    main: "#1672EC",
    contrastText: "#fff"
  },
  success: {
    main: "#47D85D",
    contrastText: "#fff"
  },
  error: {
    main: "#f44336",
    contrastText: "#fff"
  },
  tonalOffset: 0.05
};
