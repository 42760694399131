import type { Components, Theme } from "@mui/material";

export const MuiInputLabel: Components<Theme>["MuiInputLabel"] = {
  styleOverrides: {
    root: ({ theme }) =>
      theme.unstable_sx({
        fontSize: 18,
        fontWeight: 300,
        lineHeight: 1.5
      }),
    outlined: ({ theme }) =>
      theme.unstable_sx({
        top: -18,
        left: -14,
        mb: 0.75,
        color: theme.palette.primary.light
      })
  }
};
