import React from "react";

import createEmotionCache from "@/utils/createEmotionCache";
import { loggerSetup } from "@/utils/logger";
import yupSetup from "@/utils/yup";
import { EmotionCache } from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Hydrate } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { SessionProvider } from "next-auth/react";
import { appWithTranslation } from "next-i18next";
import nextI18NextConfig from "next-i18next.config.js";
import { DefaultSeo } from "next-seo";
import type { AppProps } from "next/app";
//utils
import { Toaster } from "react-hot-toast";
import { theme } from "~styles/theme/main/theme";
import "~styles/global.css";

import ErrorBoundary from "@/components/blocks/global/error-boundary/ErrorBoundary";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

// @ts-ignore
import SEO from "../../next-seo.config";

yupSetup();
loggerSetup();

const queryClient = new QueryClient();
const clientSideEmotionCache = createEmotionCache();

function App({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps: { session, ...pageProps }
}: AppProps & { emotionCache: EmotionCache }) {
  return (
    <ErrorBoundary>
      <SessionProvider
        session={session}
        refetchOnWindowFocus
        refetchInterval={0}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
              <CssBaseline />
              <DefaultSeo {...SEO} />
              <CacheProvider value={emotionCache}>
                <ThemeProvider theme={theme}>
                  {/*<Script*/}
                  {/*  strategy={"afterInteractive"}*/}
                  {/*  id="cookiehub"*/}
                  {/*  src={"https://cookiehub.net/c2/a3deb829.js"}*/}
                  {/*  onReady={() => {*/}
                  {/*    var cpm = {};*/}
                  {/*    // @ts-ignore*/}
                  {/*    if (window?.cookiehub) {*/}
                  {/*      // @ts-ignore*/}
                  {/*      window?.cookiehub?.load(cpm);*/}
                  {/*    }*/}
                  {/*  }}*/}
                  {/*  onError={e => {*/}
                  {/*    console.error("Script failed to load", e);*/}
                  {/*  }}*/}
                  {/*/>*/}
                  <Component {...pageProps} />
                  <Toaster
                    toastOptions={{
                      success: {
                        style: {
                          background: theme.palette.green.light
                        }
                      },
                      error: {
                        style: {
                          background: theme.palette.pink.light
                        }
                      },
                      style: {
                        zIndex: 10000,
                        color: theme.palette.white.main,
                        background: theme.palette.orange.light,
                        boxShadow: "none",
                        maxWidth: "300px",
                        overflow: "hidden"
                      }
                    }}
                  />
                </ThemeProvider>
              </CacheProvider>
              <ReactQueryDevtools initialIsOpen={false} />
            </Hydrate>
          </QueryClientProvider>
        </LocalizationProvider>
      </SessionProvider>
    </ErrorBoundary>
  );
}

export default appWithTranslation(App, nextI18NextConfig);
