import chalk, { ChalkInstance } from "chalk";
import log from "loglevel";
import prefix from "loglevel-plugin-prefix";

const colors = {
  TRACE: chalk.magenta,
  DEBUG: chalk.cyan,
  INFO: chalk.blue,
  WARN: chalk.yellow,
  ERROR: chalk.red
} as const;

const nextAuthLog = log.getLogger("next-auth");
const axiosLog = log.getLogger("axios");
const errorBoundaryLog = log.getLogger("error-boundary");

export function loggerSetup() {
  prefix.reg(log);
  if (process.env.NODE_ENV !== "production") {
    log.enableAll();
    nextAuthLog.enableAll();
    axiosLog.enableAll();
  }

  applyPrefix(log, chalk.green);
  applyPrefix(nextAuthLog, chalk.blue);
  applyPrefix(axiosLog, chalk.magenta);
  applyPrefix(errorBoundaryLog, chalk.red);
}

function applyPrefix(logger: log.Logger, color: ChalkInstance) {
  prefix.apply(logger, {
    format(level: string, name: string, timestamp) {
      return `${chalk.gray(`[${timestamp}]`)} ${colors[
        level.toUpperCase() as keyof typeof colors
      ](level)} ${color.bold(`${name.toUpperCase()}:`)}`;
    }
  });
}
