import type { Components, Theme } from "@mui/material";

export const MuiTextField: Components<Theme>["MuiTextField"] = {
  defaultProps: {
    InputLabelProps: {
      shrink: true
    },
    InputProps: { notched: false },
    FormHelperTextProps: {
      sx: {
        textAlign: "right"
      }
    }
  },
  styleOverrides: {
    root: ({ theme, ownerState }) =>
      theme.unstable_sx({
        mb: 1.25,
        ...(ownerState.variant === "outlined" && {
          mt: 3,
          ...(ownerState.helperText && {
            mb: -2
          })
        })
      })
  }
};
