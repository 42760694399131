let path = require("path");

module.exports = {
  i18n: {
    defaultLocale: "en",
    defaultLanguage: "en",
    locales: ["en", "nl"],
    localePath: path.resolve("./public/locales"),
    objectNotation: true
  },
  react: { useSuspense: false }
};
