import { themeComponents } from "~styles/theme/main/themeOptions/components";
import { customProperties } from "~styles/theme/main/themeOptions/custom-properties";
import { themePalette } from "~styles/theme/main/themeOptions/palette";
import { themeTypography } from "~styles/theme/main/themeOptions/typography";

import { createTheme, responsiveFontSizes } from "@mui/material";

let mainTheme = createTheme({
  typography: themeTypography,
  palette: themePalette,
  components: themeComponents,
  ...customProperties
});
export const theme = responsiveFontSizes(mainTheme);
